.redirects {
    width: 100%;
}
.redirects__heading-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.redirects__heading-row__label {
    width: 100%;
    background: rgb(0, 60, 90);
    color: white;
    padding: 4px;
    font-size: 14px;
    font-weight: 900;
    border: 1px solid #a1a1a1;
    border-bottom: 0;
}
.redirects__heading-row__label:last-of-type {
    border-left: 0;
}
.redirects__redirect {
    display: flex;
    width: 100%;
}
.redirects__redirect__input-container {
    width: 100%;
    display: flex;
    padding: 4px;
    gap: 8px;
}
.redirects__redirect__input {
    width: 100%;
    border: 1px solid #a1a1a1;
    border-radius: 4px;
    padding: 4px;
}
.redirects__redirect__button {
    flex-shrink: 0;
    border: 1px solid;
    border-radius: 4px;
    background: transparent;
    padding: 4px;
    display: grid;
    place-items: center;
    cursor: pointer;
}
.redirects__redirect__button * {
    width: 20px;
    height: 20px;
}
.redirects__redirect__button--delete {
    color: rgb(180, 0, 0);
}
.redirects__redirect__button--update {
    background: rgb(0, 60, 90);
    border: 0;
    color: white;
    animation: pulse 2s linear alternate infinite;
}
.redirects__redirect__button--update * {
    stroke: white;
}
.redirects__new-button {
    border: 0;
    padding: 0;
    font-size: 24px;
    line-height: 1px;
    border-radius: 50%;
    background-color: rgb(0, 60, 90);
    color: white;
    font-weight: 900;
    width: 36px;
    height: 36px;
    display: grid;
    place-items: center;
    margin: 8px auto;
}
