@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

:root {
    --left-width: 216px;
    --bg: var(--backgroundSecondary);
    /* side/top bar */
    --backgroundPrimary: #ffffff;
    --darkBackgroundPrimary: #1d212d;

    /* content container */
    --backgroundSecondary: #ffffff;
    --darkBackgroundSecondary: #05111c;

    /* accordion menus/some table headers/searchbar */
    /* --backgroundAccent: #f5f5f5;
    --darkBackgroundAccent: #05111c; */

    --muted: #f4f4f5;
    --muted-foreground: #a1a1aa;
    --buttonPrimary: ;
    --darkButtonPrimary: ;

    --buttonSecondary: ;
    --darkButtonSecondary: ;

    /* alternating table rows */
    --table1: #e0e4eb;
    --darkTable1: #05111c;

    --table2: #e0e4eb;
    --darkTable: #05111c;

    /* primary text color */
    --textPrimary: #4a4a4a;
    --darkTextPrimary: #eeefea;

    /* --secondaryColor:  */
    /* --darkSecondaryColor:  */

    /* orange/blue */
    --accent1: #1e1e2a;
    --darkAccent1: #42efd0;

    /* home screen sales numbers*/
    --accent2: #60bc1a;
    --darkAccent2: #60bc1a;

    /* scrollbars */
    --accent3: #003c5a;
    --darkAccent3: #ffa500;

    /* toggle button */
    --accent4: rgb(25, 118, 210);
    --darkAccent4: rgb(25, 118, 210);

    /* overdue tasks */
    --warning: #8a0f0f;
    --darkWarning: #8a0f0f;

    /* red text price scrape */
    --danger: #b40000;
    --darkDanger: #ff0000;

    /* green text price scrape */
    --success: #008000;
    --darkSuccess: #60bc1a;

    --scroll: ;
    --darkScroll: ;

    --scrollBackground: ;
    --darkScrollBackground: ;
}
input[type='color'] {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    height: 32px;
}
input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
}
input[type='color']::-webkit-color-swatch {
    border: none;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.15);
    overscroll-behavior: none;
    min-height: calc(100vh - 52px);
    background: var(--backgroundSecondary);
}
.dark body {
    background: var(--darkBackgroundSecondary);
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    font-family: 'Inter';
}
.dark option {
    background: #1d212d;
}
textarea,
input {
    font-family: 'Inter';
}
code {
    font-family: 'Inter', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
* {
    box-sizing: border-box;
    font-family: 'Inter';
}
.dark .icon-box {
    filter: invert(72%) sepia(78%) saturate(380%) hue-rotate(111deg) brightness(103%) contrast(87%);
}
.js-orders-icon:hover + .js-orders-icon-message,
.js-orders-icon-list:hover > .js-orders-icon-message,
.js-orders-icon-message:hover {
    display: flex;
}
.js-dropdown__checkbox--checked {
    background-color: rgb(0, 60, 90);
}
.js-checkbox-select__checkbox--checked {
    background-color: rgb(0, 60, 90);
}
.dark *::-webkit-scrollbar {
    width: 8px;
    background-color: var(--darkScrollBackground);
}
.dark .company-modal *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #05111c;
}
.dark *::-webkit-scrollbar-thumb {
    background-color: var(--darkScroll);
    border-radius: 99vw;
}
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--scrollBackground);
}
*::-webkit-scrollbar-thumb {
    background-color: var(--scroll);
    border-radius: 99vw;
}
.dark .js-dropdown__checkbox--checked {
    background-color: #42efd0;
}
.dark .js-checkbox-select__checkbox--checked {
    background-color: #42efd0;
}
.dark .js-select__option--checked {
    background-color: #1967d2;
}

.z-index-1 {
    z-index: 1;
}
.z-index-2 {
    z-index: 2;
}
.z-index-3 {
    z-index: 3;
}
.z-index-4 {
    z-index: 4;
}
.z-index-5 {
    z-index: 5;
}
.z-index-6 {
    z-index: 6;
}
.z-index-7 {
    z-index: 7;
}
.z-index-8 {
    z-index: 8;
}
.z-index-9 {
    z-index: 9;
}
.z-index-10 {
    z-index: 10;
}

.dark .svg-to-accent {
    filter: invert(80%) sepia(34%) saturate(806%) hue-rotate(108deg) brightness(102%) contrast(87%);
}

[list]::-webkit-calendar-picker-indicator {
    display: none !important;
}
