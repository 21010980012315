.admin__reset-products {
    display: block;
    margin: 8px 0;
    padding: 8px 16px;
    background-color: rgb(180, 0, 0);
    color: white;
    font-weight: 700;
    font-size: 24px;
    border: 0;
    cursor: pointer;
    border: 2px solid rgb(180, 0, 0);
    transition: all 0.3s;
    width: fit-content;
    text-decoration: none;
}
.admin__reset-products:hover {
    color: rgb(180, 0, 0);
    background-color: white;
}
