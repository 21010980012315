.item {
    display: flex;
    flex-direction: column;
    margin: 16px;
    padding: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    flex-wrap: wrap;
    gap: 16px;
}
.item * {
    margin: 0;
}

.cursor-pointer {
    cursor: pointer;
}
