.login {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.login__forgot {
    text-align: center;
    margin-top: 24px;
    cursor: pointer;
    font-weight: 600;
}
.login__submit {
    width: 100%;
    border: 0;
    border-radius: 8px;
    background: #89bf05;
    padding: 12px;
    color: white;
    font-weight: 700;
    font-size: 24px;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    transition: all 0.5s;
}
.login__submit:hover {
    background-color: #50c742;
    opacity: 0.8;
    box-shadow: 0px 4px 4px 0px #000000;
}
.login__heading {
    margin-top: 36px;
    text-align: center;
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 800;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow:
        0 0 0 30px white inset,
        0px 4px 4px 0px #00000040 !important;
    font-size: 16px !important;
}
.login__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 24px;
    width: 100%;
    max-width: 500px;
}
.login__input {
    width: 100%;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #00000040;
    background-color: white;
    padding: 16px;
    text-align: center;
    font-size: 16px;
}
