#layout__overlay-div {
    display: grid;
    grid-template: 100% / 100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    top: 0;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    transition-duration: 0.5s;
    z-index: -10000;
}

body {
    margin: 52px 0 0 var(--left-width);
}
.topbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 16px 32px;
    display: flex;
    align-items: flex-end;
    background-color: var(--bg);
}
.topbar__row {
    display: flex;
    align-items: flex-end;
}
.topbar__row img {
    object-fit: contain;
}
.topbar__heading {
    font-size: 16px;
    color: #696969;
    text-transform: uppercase;
    margin-bottom: 1px;
    margin-left: -16px;
    letter-spacing: 1.5px;
}
.topbar__search {
    width: 80%;
    max-width: 600px;
    display: flex;
    align-items: center;
    border: 1px solid #a1a1a1;
    border-radius: 6px;
    margin: 0 auto;
    position: relative;
}
.topbar__search__input {
    border: 0;
    border-radius: 6px;
    padding-left: 8px;
    font-size: 16px;
    color: #4a4a4a;
    width: 100%;
    height: 100%;
    padding: 8px;
    padding-left: 32px;
}
.topbar__search__icon {
    position: absolute;
    display: block;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.topbar__account {
    display: flex;
    gap: 16px;
    align-items: center;
}
.topbar__account__help {
    height: 35px;
    width: 35px;
    position: relative;
    cursor: pointer;
    border-radius: 100px;
}
.topbar__account__help-icon-left {
    height: 25px;
    width: auto;
    position: absolute;
    left: 9px;
    top: 3px;
    z-index: 3;
}
.topbar__account__help-icon-right {
    height: 45px;
    width: auto;
    position: absolute;
    left: 0;
    bottom: -3px;
    z-index: 2;
    color: black;
}
.topbar__account__notifications {
    cursor: pointer;
}
.topbar__account__user {
    display: flex;
    gap: 8px;
    padding: 8px;
    border: 1px solid #a1a1a1;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;
}
.topbar__account__user__name {
    color: #4a4a4a;
}
.topbar__account__user__avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--lime);
    display: grid;
    place-items: center;
    color: white;
    font-weight: 700;
    font-size: 18px;
}
.topbar__search__input:focus {
    border: 0;
    outline: none;
}
.sidebar {
    position: fixed;
    top: 82px;
    left: 0;
    height: calc(100% - 82px);
    width: var(--left-width);
    padding: 16px 32px;
    background-color: var(--bg);
}
.sidebar__menu__item {
    margin-bottom: 8px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #4a4a4a;
    width: fit-content;
    cursor: pointer;
}
.sidebar__menu__item--selected {
    border-bottom: 2px solid rgb(0, 60, 90);
    color: rgb(0, 60, 90);
    font-weight: 700;
    font-size: 16px;
}
.sidebar__menu__subitem {
    margin-bottom: 8px;
    margin-left: 8px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #4a4a4a;
    width: fit-content;
    cursor: pointer;
}
.sidebar__menu__subitem--selected {
    color: rgb(0, 60, 90);
    font-weight: 700;
    font-size: 14px;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
    opacity: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 15;
    display: grid;
    place-items: center;
}
.modal--visible {
    width: 100%;
    height: 100%;
    opacity: 1;
}
.modal__inner {
    background-color: white;
    padding: 32px;
    position: relative;
    width: 100%;
    max-width: 600px;
}
.modal__close {
    position: absolute;
    top: 8px;
    right: 16px;
    font-size: 20px;
    cursor: pointer;
}
.modal__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.modal__title {
    margin-bottom: 16px;
    color: #696969;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid rgb(0, 60, 90);
    text-align: center;
    padding-bottom: 8px;
}
.modal__form__row {
    width: 100%;
}
.modal__form__row label {
    color: #4a4a4a;
    font-weight: 700;
    font-size: 14px;
}
.modal__form__row input {
    width: 100%;
    height: 40px;
    border: 0;
    padding: 8px;
    border-bottom: 1px solid #4a4a4a;
}
.modal__form__submit {
    margin-top: 16px;
    border: 0;
    padding: 8px 16px;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    background-color: rgb(0, 60, 90);
    font-size: 18px;
}
.modal__form__row--flex {
    display: flex;
    gap: 8px;
}
.modal__form__row--half {
    width: 100%;
}
.modal__form__type {
    opacity: 0.7;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: -8px;
    margin-bottom: 8px;
    text-align: center;
}
.topbar__notifications {
    cursor: pointer;
}
.modal__form__override {
    display: block;
    height: 40px !important;
    width: 100% !important;
    border: 0 !important;
    border-bottom: 1px solid #4a4a4a !important;
}
