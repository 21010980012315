/* I don't think we can remove these ones. They are used in some JS */
.task-screen__field {
    transition: all 0s 0.2s;
    cursor: help;
}
.task-screen__field-body {
    visibility: hidden;
    transition: all 0s 0.2s;
}
.task-screen__field:hover + .task-screen__field-body {
    visibility: visible;
}
.task-screen__field-body:hover {
    visibility: visible;
}
.activity-field {
    font-weight: 700;
    color: #60bc1a;
}
