.calendar__container {
    box-shadow: 2px 3px 5px rgb(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: #eeeeee;
    padding: 4px;
    position: absolute;
    top: 100%;
    right: 0;
}
.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 280px;
}
.calendar__cell {
    padding: 2px;
    margin: 1px;
    text-align: left;
    background-color: white;
    font-size: 10px;
    height: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}
.calendar__cell_task-count {
    width: 16px;
    height: 16px;
    display: grid;
    place-items: center;
    font-size: 10px;
    line-height: 1px;
    background-color: orange;
    color: white;
    font-weight: 900;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.calendar__cell--prev-month {
    background-color: rgb(0, 0, 0, 0.25);
    opacity: 0.5;
}
.calendar__month-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}
.calendar__month-row__button {
    background-color: white;
    border-radius: 4px;
    border: 0;
    font-weight: 900;
    color: #a1a1a1;
    cursor: pointer;
}
.calendar__month-row__heading {
    font-weight: 700;
    color: #4a4a4a;
}
.calendar__cell--start-date {
    background-color: rgb(0, 60, 90);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
    margin-right: 0;
    font-weight: 900;
}
.calendar__cell--end-date {
    background-color: rgb(0, 60, 90);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    margin-left: 0;
    font-weight: 900;
}
.calendar__cell--included-date {
    background-color: rgb(0, 60, 90, 0.25);
    margin-left: 0;
    margin-right: 0;
}
.collections__toggle * {
    width: 24px;
    height: 24px;
    color: rgb(0, 60, 90);
    cursor: pointer;
}
.calendar__wrapper {
    position: relative;
}
